import { API } from "aws-amplify";

export default (data) => {
  const query = `query searchAssetsSeller(
    $assetStatus: [String],
    $status: [String],
    $propertyType: [String],
    $size: Int,
    $offset: Int,
    $address: String,
    $state: [String],
    $owners: [String],
    $country: String,
    $city: String,
    $name: String,
    $sortBy:ESSortingBy,
    $orderBy: OrderByChoices,
    $id:String
    ){
      listAssetsByUser:searchAssets(
            assetStatus: $assetStatus,
            status: $status,
            propertyType: $propertyType,
            size: $size,
            offset: $offset
            address: $address,
            owners: $owners,
            state: $state,
            country: $country,
            city: $city,
            name: $name,
            sortBy: $sortBy,
            orderBy: $orderBy,
            id:$id
        ) {
            assets {
                winnerOffer {
                   id
                   depositProvider
                   buyerClosingPeriod {
                     startDate
                     endDate
                   }
                   sellerClosingPeriod {
                     startDate
                     endDate
                   }
                   buyerClosingDocumentsAccepted
                   sellerClosingDocumentsAccepted
                }
                sellerClosingPeriod {
                  startDate
                  endDate
                }
                winnerOffer {
                  depositProvider
                  buyerClosingPeriod {
                    startDate
                    endDate
                  }
                  sellerClosingPeriod {
                    startDate
                    endDate
                  }
                  buyerClosingDocumentsAccepted
                  sellerClosingDocumentsAccepted
                }
                winnerOfferId
                id
                publishedTime
                dateModified
                pricingPlan
                currentStep
                registrationStatus
                name
                type
                upb
                tier_1_whitelist
                tier_2_whitelist
                ownerEmail
                draftTier2Requests
                contactsDisclosed
                sellerEntityName
                sellerFirstName
                sellerLastName
                sellerPhoneNumber
                contacts {
                  emailAddress
                  firstName
                  lastName
                  companyName
                  emailAddress
                  officePhone
                } 
               documents{
                   type
                   key
                   name
                  dateModified
                  dataOwnerId
               }
                offers 
                listingPrice {
                  amount
                }
                callForOffersPeriod {
                  startDate
                  endDate
                }
                  bestAndFinalPeriod {
                  startDate
                  endDate
                }
                underSelectionPeriod {
                  startDate
                  endDate
                }
                status
                assetStatus
                address
                creationDate
                images
                assetType
                favoriteForUsers
                numberOfCAs
                numberOfActiveOffers
                numberOfOffers
                numberOfAllActivities
                numberOfAllOffers
                numberOfTier2s
                numberOfViewedOnly
                numberOfImpressions
                totalUniqueViewers
                calculatorResult{
                  id
                  lowValueRange
                  percentageLowValueRangeToUpb
                  highValueRange
                  percentageHighValueRangeToUpb
                  noteRateFloorCalculated
                  chartData
                  lowSideSavings
                  lowSideTime
                  highSideSavings
                  highSideTime
                  foreclosureProcess
                  receivershipProcess
                  biddingProcess
                  seasoning
                  remainingTerm
                  ltv
                  XLLoanClass
                  marketTier
                  recommendation
                }
                noteOverview{
                  unpaidPrincipalBalance{
                    amount
                    currency
                  }
                  totalPayoff{
                    amount
                    currency
                  }
                }
                
                
                
              }

        }
      }`;
  return API.graphql({
    query,
    variables: {
      status: data.status
        ? data.status
        : [
          "Published",
          "CallForOffers",
          "BestAndFinal",
          "UnderSelection.CallForOffers",
          "UnderSelection.BestAndFinal",
          "ContractPending.CallForOffers",
          "ContractPending.BestAndFinal",
          "Closed",
          "Draft",
          "Published.WithOffers",
        ],
      assetStatus: data.assetStatus || null,
      propertyType: data.propertyType || null,
      size: data.size || 12,
      offset: data.offset || 0,
      address: data.address || null,
      state: data.state || null,
      country: data.country || null,
      city: data.city || null,
      owners: data.owners,
      name: data.name || null,
      sortBy: data.sortBy || null,
      orderBy: data.orderBy || null,
      id: data.id,
    },
  });
};

//normal query
