import ErrorParser from "@/utils/ErrorParser";
import Settings from "@/services/apiCalls/admin/settings/settings";
export default {
  namespaced: true,
  state: {
    userSettings: {},
  },
  getters: {
    getAutoDepositFlag(state) {
      return state.userSettings?.autoDeposit;
    },
    getSimplifiedRegisterFlag(state) {
      return state.userSettings?.passwordlessRegistrationEnabled;
    },
    getPaymentProvider(state) {
      return state.userSettings?.depositPaymentProvider;
    },
    getPaymentIframe(state) {
      return state.userSettings?.depositIframeProvider;
    },
    getDepositLimit(state) {
      return state.userSettings?.depositLimit;
    },
  },
  mutations: {
    setUserSettings(state, val) {
      state.userSettings = val;
      // state.userSettings = {
      //   autoDeposit: true,
      //   userProfileRequired: true,
      //   userNotificationsCriteriaRequired: true,
      // };
    },
  },
  actions: {
    async fetchUserSettings({ commit }) {
      try {
        const { data } = await Settings.getSettings();

        const settingsData = data.getSettings;

        commit("setUserSettings", settingsData);
      } catch (e) {
        ErrorParser(e);
      }
    },
  },
};
